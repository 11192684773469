import React from 'react';
// import dateFormat from 'dateformat';
import classNames from 'classnames';

import { Dialog, Input } from 'svs-utils/react';

import { GlobalStateComponent } from '../../../utils/reactUtils.js';

import './uploadFileDialog.scss';

class UploadFileDialog extends GlobalStateComponent {
    state = {
        selectedFiles: [],
    };

    handleInputChange(key, value) {
        this.setState({ [key]: value });
    }

    addFiles(files) {
        files = files.filter((file) => file.name.endsWith('.kml') || file.name.endsWith('.igc'));
        if (files.length > 2) {
            return alert('Only 2 files max are allowed');
        }
        if (!files.length) {
            return;
        }
        this.setState({ selectedFiles: files });
    }

    render() {
        var { close, params, submit } = this.props;
        var { selectedFiles } = this.state;

        return (
            <Dialog
                className='dialogAddFlight'
                close={close}
                header={`Upload Flight File(s) - Flight ${params.id}`}
                height={250}
                width={400}
            >
                <div className={classNames('addFlightContainer')}>
                    <Input type='file' value={selectedFiles} onChange={(files) => this.addFiles(files)} />
                    <Input type='button' label='Submit' onClick={() => submit(selectedFiles)} />
                </div>
            </Dialog>
        );
    }
}

export default UploadFileDialog;
