import React from 'react';
import * as ReactRouter from 'react-router-dom';
import { isBrowser, isMobile } from 'react-device-detect';

import { getLocalReactUtils } from 'svs-utils/react';

export function getLayoutSize() {
    if (window.innerWidth < 500) {
        return 'XS';
    } else if (window.innerWidth < 900) {
        return 'S';
    } else if (window.innerWidth < 1300) {
        return 'M';
    } else if (window.innerWidth < 1600) {
        return 'L';
    } else {
        return 'XL';
    }
};

var layoutSize = getLayoutSize();
var initialGlobalState = {
    appLayout: {
        layoutSize,
        isBrowser,
        isMobile,
        menuOverlay: isMobile || ['XS', 'S'].includes(layoutSize),
    },
    dbInfo: {
        locations: {},
        wings: {},
        paramotors: {},
        engines: {},
    },
};
export var { GlobalStateComponent, withNavigate, withParams, withProvider } = getLocalReactUtils(React, ReactRouter, initialGlobalState);
