import React, { Component } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import { ajax, sleep } from 'svs-utils/web';
import { Login } from 'svs-utils/react';

import { withNavigate, withProvider } from './utils/reactUtils.js';
import { AppLayout, Home } from './components/index.js';

class AppRouter extends Component {
    state = {
        loggedIn: null,
        colorMode: 'lightMode',
        user: {},
    };

    ajaxCalls = {
        '/checkLoggedIn': {},
    };

    async componentDidMount() {
        var { navigate } = this.props;

        var endPoint = '/checkLoggedIn';
        while (this.ajaxCalls[endPoint].running) {
            await sleep(10);
        }

        if (!this.ajaxCalls[endPoint].ran) {
            this.ajaxCalls[endPoint].running = true;
            var results = await ajax({ endPoint });
            this.ajaxCalls[endPoint].running = false;
            this.ajaxCalls[endPoint].ran = true;

            var user = {};
            if (results.result) {
                user = results.user;
            } else {
                var path = window.location.pathname;
                if (path !== '/') {
                    navigate('/');
                }
            }
            this.setState({ loggedIn: results.result, user });
        }
    }

    login(username, password) {
        ajax({ endPoint: '/login', data: { username, password } }).then((results) => {
            if (!results.result) {
                alert(results.desc);
            }
            this.setState({ loggedIn: results.result, user: results.user });
        });
    }

    logout() {
        var { navigate } = this.props;
        ajax({ endPoint: '/logout' }).then((results) => {
            if (results.result) {
                navigate('/');
                this.setState({ entryMonths: {} });
            }

            this.setState({ loggedIn: !results.result });
        });
    }

    render() {
        var { colorMode, loggedIn, user } = this.state;

        var loggedInRouter = null;
        if (loggedIn === null) {
            loggedInRouter = <Route index element={<div>Loading</div>} />;
        } else if (!loggedIn) {
            loggedInRouter = <Route index element={<Login login={(username, password) => this.login(username, password)} />} />;
        } else {
            var loginComponentProps = {
                logout: () => this.logout(),
                user,
            };

            loggedInRouter = (
                <React.Fragment>
                    <Route path=':homePath' element={<Home {...loginComponentProps} />}>
                        <Route path=':homePath2' element={<Home {...loginComponentProps} />}></Route>
                    </Route>
                    <Route index element={<Navigate to='/flightlogs' />} />
                </React.Fragment>
            );
        }

        return (
            <Routes>
                <Route path='/' element={<AppLayout className={colorMode} />}>
                    <Route path='*' element={<div>404</div>} />
                    <Route path='404' element={<div>404</div>} />
                    {loggedInRouter}
                </Route>
            </Routes>
        );
    }
}

export default withNavigate(withProvider(AppRouter));
