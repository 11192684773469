import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
// import dateFormat from 'dateformat';

import { ajax } from 'svs-utils/web';

import { GlobalStateComponent } from '../../utils/reactUtils.js';
import { FlightLogs } from './content/index.js';
import './home.scss';

class Home extends GlobalStateComponent {

    constructor(props) {
        super(props);

        this.menuItems = {
            flightlogs: { title: 'Flight Logs', shouldShow: () => true, content: () => <FlightLogs /> },
        };

        var appLayout = this.getGlobalStateSlice('appLayout');

        this.state = {
            menuOpen: !appLayout.isMobile && !['XS', 'S'].includes(appLayout.layoutSize),
        };
    }

    async componentDidMount() {
        var { navigate, urlParams: { homePath } } = this.props;

        var path = homePath?.toLowerCase();

        if (!this.menuItems[path] || (!this.menuItems[path].shouldShow?.() && !this.menuItems[path].onlyDirect)) {
            navigate('/404');
            return;
        }

        var dbInfo = this.getGlobalStateSlice('dbInfo');
        if (!Object.keys(dbInfo.locations).length) {
            var results = await ajax({ endPoint: '/getDbTablesInfo' });
            if (results.result) {
                var { locations, wings, paramotors, engines } = results;
                this.setGlobalStateSlice('dbInfo', { locations, wings, paramotors, engines });
            }
        }
    }

    render() {
        var { logout, urlParams: { homePath } } = this.props;
        var { menuOpen } = this.state;

        var appLayout = this.getGlobalStateSlice('appLayout');

        homePath = homePath?.toLowerCase();

        return (
            <div className='home'>
                <div className='mainToolBar homeBoxShadow noSelect'>
                    <div className='mainMenuButton' onClick={() => this.setState({ menuOpen: !menuOpen })}>Menu</div>
                    <div className='titleBar'>Dashboard - {this.menuItems[homePath]?.title}</div>
                    <div className='logoutButton' onClick={() => logout()}>Logout</div>
                </div>
                {menuOpen && (
                    <div className={classNames('mainMenu homeBoxShadow', { menuOverlay: appLayout.menuOverlay })}>
                        {Object.keys(this.menuItems).filter((itemKey) => (this.menuItems[itemKey].shouldShow?.() && !this.menuItems[itemKey].onlyDirect)).map((itemKey) => (
                            <Link
                                className={classNames('menuItem', { selected: homePath === itemKey })}
                                key={itemKey}
                                to={`/${itemKey}`}
                                onClick={() => appLayout.menuOverlay && this.setState({ menuOpen: false })}
                            >
                                {this.menuItems[itemKey].title}
                            </Link>
                        ))}
                    </div>
                )}
                <div
                    className={classNames('homeContentContainer')}
                    style={{ gridColumn: `span ${(menuOpen && !appLayout.menuOverlay) ? 1 : 2}` }}
                >
                    <div className='homeContent'>
                        {this.menuItems[homePath]?.content()}
                    </div>
                </div>
            </div>
        );
    }
}

export default Home.wrapper;
