import React from 'react';
import { Outlet } from 'react-router-dom';

import './app-layout.scss';

import { getLayoutSize, GlobalStateComponent } from '../../utils/reactUtils.js';

class AppLayout extends GlobalStateComponent {

    componentGlobalSliceName = 'appLayout';

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = () => {
        var { isMobile, layoutSize } = this.stateSlice;

        var newLayoutSize = getLayoutSize();
        if (newLayoutSize !== layoutSize) {
            this.setStateSlice({
                layoutSize: newLayoutSize,
                menuOverlay: isMobile || ['XS', 'S'].includes(newLayoutSize),
            });
        }
    };

    render() {
        var { className } = this.props;
        return (
            <div className={`appLayout ${className}`}>
                <Outlet />
            </div>
        );
    }
}

export default AppLayout.wrapper;
