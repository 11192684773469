import React from 'react';
// import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';

import { ajax, parseDate } from 'svs-utils/web';
import { Table2 } from 'svs-utils/react';

import { GlobalStateComponent } from '../../../../utils/reactUtils.js';
import { UploadFileDialog } from '../../../dialogs/dialogs.js';

import './flightLogs.scss';

class FlightLogs extends GlobalStateComponent {

    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            flightLogs: [],
            lastUpdated: null,
            uploadFileDialog: null,
        };
    }

    componentDidMount() {
        this.getFlightLogs();
    }

    async getFlightLogs() {
        var results = await ajax({ endPoint: '/getFlightLogs' });
        if (results.result) {
            var flightLogs = results.flightLogs.map((flightLog) => ({
                ...flightLog,
                date: parseDate(flightLog.date),
            }))
            this.setState({ flightLogs, lastUpdated: new Date() });
        } else {
            this.setState({ errorMessage: results.desc });
        }
    }

    navigateTo(path, event = {}) {
        var { navigate } = this.props;

        if (event.metaKey || event.ctrlKey) {
            window.open(path, '_blank');
        } else {
            navigate(path);
        }
    }

    async handleUpload(files) {
        var { uploadFileDialog } = this.state;

        var filesData = new FormData();

        for (var file of files) {
            filesData.append('flightFiles', file, file.name);
        }

        var results = await ajax({ endPoint: '/uploadFiles', data: { flightId: uploadFileDialog.id }, filesData });
        if (results.result) {
            var { flightLogs } = this.state;

            var flightLog = flightLogs.find((log) => log.id === uploadFileDialog.id);
            if (flightLog) {
                flightLog.otherInfo = { ...flightLog.otherInfo, ...results.otherInfo };
            }

            this.setState({ flightLogs, uploadFileDialog: null });
        } else {
            alert(results.desc);
        }
    }

    render() {
        var { flightLogs, errorMessage, lastUpdated, uploadFileDialog } = this.state;

        // var appLayout = this.getGlobalStateSlice('appLayout');
        // var smallBotContainer = appLayout.isMobile || ['XS', 'S'].includes(appLayout.layoutSize);

        var flightLogsClickGroups = [
            [
                { name: 'Edit', onClick: (flightLog) => {} },
                {
                    name: 'Upload Files',
                    onClick: (flightLog) => this.setState({ uploadFileDialog: { ...flightLog } }),
                    shouldShow: (flightLog) => !(flightLog.otherInfo.igcFile && flightLog.otherInfo.kmlFile),
                },
            ],
        ];

        var flightLogsTableHeaderGroups = [
            // { name: 'Num', headers: ['id'] },
            { name: 'Launch', headers: ['id', 'date', 'season', 'timeOfDay', 'location'] },
            { name: 'Equipment', headers: ['wing', 'paramotor', 'engine', 'odometer', 'fuelUsed'] },
            { name: 'Flight', headers: ['duration', 'flightNotes'] },
            { name: 'Weather', headers: ['temperature', 'wind', 'gusts', 'windDirection', 'weatherSummary', 'weatherNotes'] },
        ];
        var flightLogsTableHeaders = [
            { key: 'id', label: 'Flight', dataType: 'number', className: 'centered', width: 50 },
            { key: 'date', label: 'Date', dataType: 'date', width: 180, dateFormat: 'ddd mmm d, yyyy h:MM tt' },
            { key: 'season', label: 'Season', dataType: 'string', width: 70 },
            { key: 'timeOfDay', label: 'Time of Day', dataType: 'string', width: 100 },
            { key: 'location', label: 'Location', dataType: 'string', width: 200 },
            { key: 'wing', label: 'Wing', dataType: 'string', width: 150 },
            { key: 'paramotor', label: 'Paramotor', dataType: 'string', width: 150 },
            { key: 'engine', label: 'Engine', dataType: 'string', width: 150 },
            { key: 'odometer', label: 'Odometer', dataType: 'number', width: 80 },
            { key: 'fuelUsed', label: 'Fuel Used', dataType: 'number', width: 80 },
            { key: 'duration', label: 'Duration', dataType: 'string', width: 80 },
            { key: 'flightNotes', label: 'Flight Notes', dataType: 'string', width: 150 },
            { key: 'temperature', label: 'Temperature', dataType: 'number', width: 100 },
            { key: 'wind', label: 'Wind Speed', dataType: 'number', width: 100 },
            { key: 'gusts', label: 'Gusts Speed', dataType: 'number', width: 100 },
            { key: 'windDirection', label: 'Wind Direction', dataType: 'string', width: 110 },
            { key: 'weatherSummary', label: 'Weather Summary', dataType: 'string', width: 150 },
            { key: 'weatherNotes', label: 'Weather Notes', dataType: 'string', width: 150 },
        ];
        var flightLogsTableRows = flightLogs.map((flightLog) => ({
            ...flightLog,
            rowId: flightLog.id,
            noContextObject: flightLog,
        }));

        return (
            <React.Fragment>
                {uploadFileDialog && (
                    <UploadFileDialog
                        params={uploadFileDialog}
                        close={() => this.setState({ uploadFileDialog: null })}
                        submit={(files) => this.handleUpload(files)}
                    />
                )}
                <div className='bots'>
                    <div className='botsHeader'>
                        <div className='contentHeader'>Flight Logs</div>
                        <div className='lastUpdatedContainer'>{lastUpdated && `Last Updated: ${dateFormat(lastUpdated, 'mmm dS, yyyy "at" hh:MM:ss tt')}`}</div>
                    </div>
                    {errorMessage || (
                        <React.Fragment>
                            <div className='botsSectionHeader'>Logs</div>
                            <div className='botsChartContainer homeBoxShadow'>
                                {!!flightLogsTableRows.length && (
                                    <Table2
                                        headerGroups={flightLogsTableHeaderGroups}
                                        headers={flightLogsTableHeaders}
                                        rows={flightLogsTableRows}
                                        clickGroups={flightLogsClickGroups}
                                        alternateColors={true}
                                        maxHeight={500}
                                    />
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default FlightLogs.wrapper;
